
export const createClient = (state) => {
  return state.portfolio.create_client;
};
export const createInterest = (state) => {
  return state.portfolio.create_interest;
};
export const createInvest = (state) => {
  return state.portfolio.create_invest;
};

export const readClients = (state) => {
  return state.portfolio.read_clients;
};
export const readSummary = (state) => {
  return state.portfolio.read_summary;
};
export const readReport = (state) => {
  return state.portfolio.read_report;
};

export const updateClient = (state) => {
  return state.portfolio.update_client;
};
export const updateInterest = (state) => {
  return state.portfolio.update_interest;
};
export const updateInvest = (state) => {
  return state.portfolio.update_invest;
};

export const deleteClient = (state) => {
  return state.portfolio.delete_client;
};
export const deleteInterest = (state) => {
  return state.portfolio.delete_interest;
};
export const deleteInvest = (state) => {
  return state.portfolio.delete_invest;
};
export const sendReport = (state) => {
  return state.portfolio.send_report;
};

export const readWP = (state) => {
  return state.portfolio.read_wp;
};