import types from "redux/types";

export const createClientReq = (payload) => {
  return {
    type: types.protfolio.CREATE_CLIENTS,
    payload,
  };
};
export const createClientSuccess = (payload) => {
  return {
    type: types.protfolio.CREATE_CLIENTS_SUCCESS,
    payload,
  };
};
export const createClientError = (payload) => {
  return {
    type: types.protfolio.CREATE_CLIENTS_ERROR,
    payload,
  };
};

export const readClientReq = (payload) => {
  return {
    type: types.protfolio.READ_CLIENTS,
    payload,
  };
};
export const readClientSuccess = (payload) => {
  return {
    type: types.protfolio.READ_CLIENTS_SUCCESS,
    payload,
  };
};
export const readClientError = (payload) => {
  return {
    type: types.protfolio.READ_CLIENTS_ERROR,
    payload,
  };
};

export const readSummaryReq = (payload) => {
  return {
    type: types.protfolio.READ_SUMMARY,
    payload,
  };
};
export const readSummarySuccess = (payload) => {
  return {
    type: types.protfolio.READ_SUMMARY_SUCCESS,
    payload,
  };
};
export const readSummaryError = (payload) => {
  return {
    type: types.protfolio.READ_SUMMARY_ERROR,
    payload,
  };
};

export const readReportReq = (payload) => {
  return {
    type: types.protfolio.READ_REPORT,
    payload,
  };
};
export const readReportSuccess = (payload) => {
  return {
    type: types.protfolio.READ_REPORT_SUCCESS,
    payload,
  };
};
export const readReportError = (payload) => {
  return {
    type: types.protfolio.READ_REPORT_ERROR,
    payload,
  };
};

export const editClientReq = (payload) => {
  return {
    type: types.protfolio.EDIT_CLIENTS,
    payload,
  };
};
export const editClientSuccess = (payload) => {
  return {
    type: types.protfolio.EDIT_CLIENTS_SUCCESS,
    payload,
  };
};
export const editClientError = (payload) => {
  return {
    type: types.protfolio.EDIT_CLIENTS_ERROR,
    payload,
  };
};

export const deleteClientReq = (payload) => {
  return {
    type: types.protfolio.DELETE_CLIENTS,
    payload,
  };
};
export const deleteClientSuccess = (payload) => {
  return {
    type: types.protfolio.DELETE_CLIENTS_SUCCESS,
    payload,
  };
};
export const deleteClientError = (payload) => {
  return {
    type: types.protfolio.DELETE_CLIENTS_ERROR,
    payload,
  };
};

//interest
export const createInterestReq = (payload) => {
  return {
    type: types.protfolio.CREATE_INTEREST,
    payload,
  };
};
export const createInterestSuccess = (payload) => {
  return {
    type: types.protfolio.CREATE_INTEREST_SUCCESS,
    payload,
  };
};
export const createInterestError = (payload) => {
  return {
    type: types.protfolio.CREATE_INTEREST_ERROR,
    payload,
  };
};

export const editInterestReq = (payload) => {
  return {
    type: types.protfolio.EDIT_INTEREST,
    payload,
  };
};
export const editInterestSuccess = (payload) => {
  return {
    type: types.protfolio.EDIT_INTEREST_SUCCESS,
    payload,
  };
};
export const editInterestError = (payload) => {
  return {
    type: types.protfolio.EDIT_INTEREST_ERROR,
    payload,
  };
};

export const deleteInterestReq = (payload) => {
  return {
    type: types.protfolio.DELETE_INTEREST,
    payload,
  };
};
export const deleteInterestSuccess = (payload) => {
  return {
    type: types.protfolio.DELETE_INTEREST_SUCCESS,
    payload,
  };
};
export const deleteInterestError = (payload) => {
  return {
    type: types.protfolio.DELETE_INTEREST_ERROR,
    payload,
  };
};

//invest
export const createInvestReq = (payload) => {
  return {
    type: types.protfolio.CREATE_INVEST,
    payload,
  };
};
export const createInvestSuccess = (payload) => {
  return {
    type: types.protfolio.CREATE_INVEST_SUCCESS,
    payload,
  };
};
export const createInvestError = (payload) => {
  return {
    type: types.protfolio.CREATE_INVEST_ERROR,
    payload,
  };
};

export const editInvestReq = (payload) => {
  return {
    type: types.protfolio.EDIT_INVEST,
    payload,
  };
};
export const editInvestSuccess = (payload) => {
  return {
    type: types.protfolio.EDIT_INVEST_SUCCESS,
    payload,
  };
};
export const editInvestError = (payload) => {
  return {
    type: types.protfolio.EDIT_INVEST_ERROR,
    payload,
  };
};

export const deleteInvestReq = (payload) => {
  return {
    type: types.protfolio.DELETE_INVEST,
    payload,
  };
};
export const deleteInvestSuccess = (payload) => {
  return {
    type: types.protfolio.DELETE_INVEST_SUCCESS,
    payload,
  };
};
export const deleteInvestError = (payload) => {
  return {
    type: types.protfolio.DELETE_INVEST_ERROR,
    payload,
  };
};

export const sendReportReq = (payload) => {
  return {
    type: types.protfolio.SEND_REPORT,
    payload,
  };
};
export const sendReportSuccess = (payload) => {
  return {
    type: types.protfolio.SEND_REPORT_SUCCESS,
    payload,
  };
};
export const sendReportError = (payload) => {
  return {
    type: types.protfolio.SEND_REPORT_ERROR,
    payload,
  };
};

export const readWPReq = (payload) => {
  return {
    type: types.protfolio.READ_WP,
    payload,
  };
};
export const readWPSuccess = (payload) => {
  return {
    type: types.protfolio.READ_WP_SUCCESS,
    payload,
  };
};
export const readWPError = (payload) => {
  return {
    type: types.protfolio.READ_WP_ERROR,
    payload,
  };
};