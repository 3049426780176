import types from "redux/types";

export const createIAccountInit = (payload) => {
  return {
    type: types.iaccounts.CREATE_IACCOUNT,
    payload,
  };
};
export const createIAccountSuccess = (payload) => {
  return {
    type: types.iaccounts.CREATE_IACCOUNT_SUCCESS,
    payload,
  };
};
export const createIAccountError = (payload) => {
  return {
    type: types.iaccounts.CREATE_IACCOUNT_ERROR,
    payload,
  };
};


export const updateIAccountInit = (payload) => {
  return {
    type: types.iaccounts.UPDATE_IACCOUNT,
    payload,
  };
};
export const updateIAccountSuccess = (payload) => {
  return {
    type: types.iaccounts.UPDATE_IACCOUNT_SUCCESS,
    payload,
  };
};
export const updateIAccountError = (payload) => {
  return {
    type: types.iaccounts.UPDATE_IACCOUNT_ERROR,
    payload,
  };
};


export const readIAccountInit = (payload) => {
  return {
    type: types.iaccounts.READ_IACCOUNT,
    payload,
  };
};
export const readIAccountSuccess = (payload) => {
  return {
    type: types.iaccounts.READ_IACCOUNT_SUCCESS,
    payload,
  };
};
export const readIAccountError = (payload) => {
  return {
    type: types.iaccounts.READ_IACCOUNT_ERROR,
    payload,
  };
};



export const deleteIAccountInit = (payload) => {
  return {
    type: types.iaccounts.DELETE_IACCOUNT,
    payload,
  };
};
export const deleteIAccountSuccess = (payload) => {
  return {
    type: types.iaccounts.DELETE_IACCOUNT_SUCCESS,
    payload,
  };
};
export const deleteIAccountError = (payload) => {
  return {
    type: types.iaccounts.DELETE_IACCOUNT_ERROR,
    payload,
  };
};


export const readAllIAccountsInit = (payload) => {
  return {
    type: types.iaccounts.READ_IACCOUNTS,
    payload,
  };
};
export const readAllIAccountsSuccess = (payload) => {
  return {
    type: types.iaccounts.READ_IACCOUNTS_SUCCESS,
    payload,
  };
};
export const readAllIAccountsError = (payload) => {
  return {
    type: types.iaccounts.READ_IACCOUNTS_ERROR,
    payload,
  };
};

//summary
export const readISummaryInit = (payload) => {
  return {
    type: types.iaccounts.READ_ISUMMARY,
    payload,
  };
};
export const readISummarySuccess = (payload) => {
  return {
    type: types.iaccounts.READ_ISUMMARY_SUCCESS,
    payload,
  };
};
export const readISummaryError = (payload) => {
  return {
    type: types.iaccounts.READ_ISUMMARY_ERROR,
    payload,
  };
};

//daily summary
export const readIDailySummaryInit = (payload) => {
  return {
    type: types.iaccounts.READ_IDAILYSUMMARY,
    payload,
  };
};
export const readIDailySummarySuccess = (payload) => {
  return {
    type: types.iaccounts.READ_IDAILYSUMMARY_SUCCESS,
    payload,
  };
};
export const readIDailySummaryError = (payload) => {
  return {
    type: types.iaccounts.READ_IDAILYSUMMARY_ERROR,
    payload,
  };
};

//account summary
export const readCompaniesInit = (payload) => {
  return {
    type: types.iaccounts.READ_COMPANIES,
    payload,
  };
};
export const readCompaniesSuccess = (payload) => {
  return {
    type: types.iaccounts.READ_COMPANIES_SUCCESS,
    payload,
  };
};
export const readCompaniesError = (payload) => {
  return {
    type: types.iaccounts.READ_COMPANIES_ERROR,
    payload,
  };
};

//trades
export const readTradesInit = (payload) => {
  return {
    type: types.iaccounts.READ_TRADES,
    payload,
  };
};
export const readTradesSuccess = (payload) => {
  return {
    type: types.iaccounts.READ_TRADES_SUCCESS,
    payload,
  };
};
export const readTradesError = (payload) => {
  return {
    type: types.iaccounts.READ_TRADES_ERROR,
    payload,
  };
};


//read deposit
export const readDepositInit = (payload) => {
  return {
    type: types.iaccounts.READ_DEPOSIT,
    payload,
  };
};
export const readDepositSuccess = (payload) => {
  return {
    type: types.iaccounts.READ_DEPOSIT_SUCCESS,
    payload,
  };
};
export const readDepositError = (payload) => {
  return {
    type: types.iaccounts.READ_DEPOSIT_ERROR,
    payload,
  };
};

//update deposit
export const updateDepositInit = (payload) => {
  return {
    type: types.iaccounts.UPDATE_DEPOSIT,
    payload,
  };
};
export const updateDepositSuccess = (payload) => {
  return {
    type: types.iaccounts.UPDATE_DEPOSIT_SUCCESS,
    payload,
  };
};
export const updateDepositError = (payload) => {
  return {
    type: types.iaccounts.UPDATE_DEPOSIT_ERROR,
    payload,
  };
};

//create deposit
export const createDepositInit = (payload) => {
  return {
    type: types.iaccounts.CREATE_DEPOSIT,
    payload,
  };
};
export const createDepositSuccess = (payload) => {
  return {
    type: types.iaccounts.CREATE_DEPOSIT_SUCCESS,
    payload,
  };
};
export const createDepositError = (payload) => {
  return {
    type: types.iaccounts.CREATE_DEPOSIT_ERROR,
    payload,
  };
};


//read Overview 
export const readOverviewInit = (payload) => {
  return {
    type: types.iaccounts.READ_OVERVIEW,
    payload,
  };
};
export const readOverviewSuccess = (payload) => {
  return {
    type: types.iaccounts.READ_OVERVIEW_SUCCESS,
    payload,
  };
};
export const readOverviewError = (payload) => {
  return {
    type: types.iaccounts.READ_OVERVIEW_ERROR,
    payload,
  };
};

//