import { get, post, remove } from "utils/request";

export async function createAccountReq(payload) {
  const {accessToken, data} = payload;
  return post("/investors/account", data, accessToken);
}

export async function updateAccountReq(payload) {
  console.log({payload});
  const {accessToken, data, account_number} = payload;
  return post(`/investors/account/${account_number}`, data, accessToken);
}

export async function readAccountReq(payload) {
  const {accessToken, account_number} = payload;
  return get(`/investors/account/${account_number}`, accessToken);
}

export async function deleteAccountReq(payload) {
  const {accessToken, account_number} = payload;
  return remove(`/investors/account/${account_number}`, accessToken);
}

export async function readAllAccountsReq(payload) {
  const {accessToken, company} = payload;
  return get('/investors/accounts/'+company, accessToken);
}

export async function readSummary(payload) {
  const {accessToken, company} = payload;
  return get('/investors/summary/'+company, accessToken);
}

export async function readAccountSummary(payload) {
  const {accessToken, account_number} = payload;
  return get(`/investors/summary/account/${account_number}`, accessToken);
}

export async function readCompanies(payload) {
  const {accessToken, company} = payload;
  return get('/investors/companies/'+company, accessToken);
}

export async function readTrades(payload) {
  const {accessToken, data} = payload;
  return post('/investors/trades', data, accessToken);
}

export async function readDeposit(payload) {
  const {accessToken} = payload;
  return get('/investors/getDeposit', accessToken);
}

export async function createDeposit(payload) {
  const {accessToken, data} = payload;
  return post('/investors/deposit/create', data, accessToken);
}

export async function updateDeposit(payload) {
  const {accessToken, data, id} = payload;
  return post('/investors/deposit/update/'+id, data, accessToken);
}

export async function readOverview(payload) {
  const {accessToken} = payload;
  return get('/investors/overview', accessToken);
}