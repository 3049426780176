import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  accountInfoSuccess,
  accountInfoError,
  tradesInfoSuccess,
  tradesInfoError,
  tradesOpenSuccess,
  tradesOpenError
} from "redux/actions/dashboard";

import {
  accountInfoReq,
  tradeInfoReq,
  tradeOpenReq
} from "api/dashboard";

import {
  message
} from "antd";


function* accountInfo(action) {
  try {
    const response = yield call(accountInfoReq, action.payload);

    const {
      error
    } = response;
    if (error) throw error;

    yield put(accountInfoSuccess(response));

  } catch (error) {
    message.error('Error Account Info: ' + error?.message.toString());
    yield put(accountInfoError(error));
  }
}

function* accountsInfoSage() {
  yield takeEvery(types.dashboard.ACCOUNTS_INFO, accountInfo);
}

function* tradesInfo(action) {
  try {
    const response = yield call(tradeInfoReq, action.payload);
    const {
      error
    } = response;
    if (error) throw error;
    yield put(tradesInfoSuccess(response));

  } catch (error) {
    message.error('Error Trades Info: ' + error?.message.toString());
    yield put(tradesInfoError(error));
  }
}

function* tradesInfoSage() {
  yield takeEvery(types.dashboard.TRADES_INFO, tradesInfo);
}

function* tradesOpen(action) {
  try {
    const response = yield call(tradeOpenReq, action.payload);
    const {
      error
    } = response;
    if (error) throw error;
    yield put(tradesOpenSuccess(response));

  } catch (error) {
    message.error('Error Trades Info: ' + error?.message.toString());
    yield put(tradesOpenError(error));
  }
}

function* tradesOpenSage() {
  yield takeEvery(types.dashboard.TRADES_OPEN, tradesOpen);
}



//add all the saga
export default function* accountsSaga() {
  yield all([
    accountsInfoSage(),
    tradesInfoSage(),
    tradesOpenSage(),

  ]);
}