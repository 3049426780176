import auth from "redux/types/auth";
import currentUser from "redux/types/currentUser";
import theme from "redux/types/theme";
import users from "redux/types/users";
import cUsers from "redux/types/cUsers";
import accounts from "redux/types/accounts";
import fundedAccounts from "redux/types/fundedAccounts";
import statistics from "redux/types/statistics";
import challenges from "redux/types/challenges";
import dashboard from "redux/types/dashboard";
import rules from "redux/types/rules";
import iaccounts from "redux/types/investors";
import protfolio from "redux/types/portfolio";

export default {
  auth,
  currentUser,
  theme,
  users,
  accounts,
  statistics,
  challenges,
  fundedAccounts,
  dashboard,
  rules,
  iaccounts,
  protfolio,
  cUsers
};
