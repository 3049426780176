import {
  get,
  post
} from "utils/request";

export async function login(loginData) {
  const data = {
    email: loginData.email,
    password: loginData.password,
    role: 'PMW'
  };
  return post("/auth/team-login", data);
}

export async function signup(signupData) {
  return post("/auth/register", signupData);
}

export async function verifyEmail(verificationToken) {
  return get(`/auth/email-verification/${verificationToken}`);
}

export async function forgotPassword(forgotPasswordParams) {
  return post("/auth/reset-password-link", forgotPasswordParams);
}

export async function resetPassword(resetPasswordParams) {
  return post("/auth/reset-password", resetPasswordParams);
}