import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  createIAccountError,
  createIAccountSuccess,
  updateIAccountError,
  updateIAccountSuccess,
  readIAccountError,
  readIAccountSuccess,
  readAllIAccountsError,
  readAllIAccountsSuccess,
  deleteIAccountError,
  deleteIAccountSuccess,
  readISummaryError,
  readISummarySuccess,
  readIDailySummarySuccess,
  readIDailySummaryError,
  readCompaniesSuccess,
  readCompaniesError,
  readTradesSuccess,
  readTradesError,

  readDepositError,
  readDepositSuccess,
  updateDepositError,
  updateDepositSuccess,
  createDepositError,
  createDepositSuccess,

  readOverviewError,
  readOverviewSuccess
} from "redux/actions/investors";

import {
  createAccountReq,
  updateAccountReq,
  readAccountReq,
  readAllAccountsReq,
  deleteAccountReq,
  readSummary,
  readAccountSummary,
  readCompanies, 
  readTrades, 
  readDeposit,
  updateDeposit,
  readOverview,
  createDeposit
} from "api/investors";

import {
  message
} from "antd";

function* createIAccount(action) {
  try {
    const response = yield call(createAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw response;

    message.info('Investor Account Successfully Created!!!');
    yield put(createIAccountSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error('Error Creating Investors Account. ');
    yield put(createIAccountError(error));
  }
}

function* createIAccountSaga() {
  yield takeEvery(types.iaccounts.CREATE_IACCOUNT, createIAccount);
}

function* updateIAccount(action) {
  try {
    const response = yield call(updateAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw response;

    message.success('Investor Account Successfully Updated!!!');
    yield put(updateIAccountSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error('Error Updating Investors Account. ');

    yield put(updateIAccountError(error));
  }
}

function* updateIAccountSaga() {
  yield takeEvery(types.iaccounts.UPDATE_IACCOUNT, updateIAccount);
}

function* readIAccount(action) {
  try {
    const response = yield call(readAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw response;

    yield put(readIAccountSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error('Error Reading Investors Account. ');

    yield put(readIAccountError(error));
  }
}

function* readIAccountSaga() {
  yield takeEvery(types.iaccounts.READ_IACCOUNT, readIAccount);
}

function* readAllIAccounts(action) {
  try {
    const response = yield call(readAllAccountsReq, action.payload);

    const {
      error
    } = response;
    if (error) throw response;

    yield put(readAllIAccountsSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error('Error Reading Investors Accounts. ');

    yield put(readAllIAccountsError(error));
  }
}

function* readAllIAccountsSaga() {
  yield takeEvery(types.iaccounts.READ_IACCOUNTS, readAllIAccounts);
}

function* deleteIAccount(action) {
  try {
    const response = yield call(deleteAccountReq, action.payload);

    const {
      error
    } = response;
    if (error) throw response;

    message.success('Investor Account Successfully Deleted!!!');
    yield put(deleteIAccountSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error('Error Deleting Investor Account. ');

    yield put(deleteIAccountError(error));
  }
}

function* deleteIAccountSaga() {
  yield takeEvery(types.iaccounts.DELETE_IACCOUNT, deleteIAccount);
}

//summary
function* readISummary(action) {
  try {
    const response = yield call(readSummary, action.payload);

    const {
      error
    } = response;
    if (error) throw response;

    yield put(readISummarySuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error('Error Reading Summary.');

    yield put(readISummaryError(error));
  }
}

function* readISummarySaga() {
  yield takeEvery(types.iaccounts.READ_ISUMMARY, readISummary);
}

//daily account summary
function* readIDailySummary(action) {
  try {
    const response = yield call(readAccountSummary, action.payload);

    const {
      error
    } = response;
    if (error) throw response;

    yield put(readIDailySummarySuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error('Error Reading Chart Data.');
    
    yield put(readIDailySummaryError(error));
  }
}

function* readIDailySummarySaga() {
  yield takeEvery(types.iaccounts.READ_IDAILYSUMMARY, readIDailySummary);
}

//daily
function* readCompaniesFn(action) {
  try {
    const response = yield call(readCompanies, action.payload);
    
    const {
      error
    } = response;
    if (error) throw response;

    yield put(readCompaniesSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Reading Companies Chart Data.");

    yield put(readCompaniesError(error));
  }
}

function* readCompaniesSaga() {
  yield takeEvery(types.iaccounts.READ_COMPANIES, readCompaniesFn);
}
//daily
function* readTradesFn(action) {
  try {
    const response = yield call(readTrades, action.payload);
    
    const {
      error
    } = response;
    if (error) throw response;

    yield put(readTradesSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Reading trades Chart Data.");

    yield put(readTradesError(error));
  }
}

function* readTradesSaga() {
  yield takeEvery(types.iaccounts.READ_TRADES, readTradesFn);
}


function* readDepositFn(action) {
  try {
    const response = yield call(readDeposit, action.payload);
    
    const {
      error
    } = response;
    if (error) throw response;

    yield put(readDepositSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Reading Bank Balance.");

    yield put(readDepositError(error));
  }
}

function* readDepositSaga() {
  yield takeEvery(types.iaccounts.READ_DEPOSIT, readDepositFn);
}

function* updateDepositFn(action) {
  try {
    const response = yield call(updateDeposit, action.payload);
    
    const {
      error
    } = response;
    if (error) throw response;

    message.success("Successfully Updated Bank Balance");
    yield put(updateDepositSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Updating Current Bank Balance.");

    yield put(updateDepositError(error));
  }
}

function* updateDepositSaga() {
  yield takeEvery(types.iaccounts.UPDATE_DEPOSIT, updateDepositFn);
}
function* createDepositFn(action) {
  try {
    const response = yield call(createDeposit, action.payload);
    
    const {
      error
    } = response;
    if (error) throw response;

    message.success("Successfully Created Bank Balance");
    yield put(createDepositSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error adding  Bank Balance.");

    yield put(createDepositError(error));
  }
}

function* createDepositSaga() {
  yield takeEvery(types.iaccounts.CREATE_DEPOSIT, createDepositFn);
}

function* readOverviewFn(action) {
  try {
    const response = yield call(readOverview, action.payload);
    
    const {
      error
    } = response;
    if (error) throw response;

    yield put(readOverviewSuccess(response));

  } catch ({error}) {
    if(error?.message) message.error(error?.message?.toString());
    else message.error("Error Reading Overview Data.");

    yield put(readOverviewError(error));
  }
}

function* readOverviewSaga() {
  yield takeEvery(types.iaccounts.READ_OVERVIEW, readOverviewFn);
}


//

//add all the saga
export default function* iaccountsSaga() {
  yield all([
    createIAccountSaga(),
    updateIAccountSaga(), 
    readIAccountSaga(), 
    deleteIAccountSaga(), 
    readAllIAccountsSaga(),
    readISummarySaga(),
    readIDailySummarySaga(),
    readCompaniesSaga(),
    readTradesSaga(),
    readDepositSaga(),
    updateDepositSaga(),
    createDepositSaga(),
    readOverviewSaga(),
  ]);
}