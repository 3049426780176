export default {
  index: "/",
  signup: "/signup",
  logout: "/logout",
  dashboard: "/dashboard",
  editProfile: "/edit-profile",
  changePassword: "/changePassword",
  settings: "/settings",
  users: "/users",
  cUsers: "/cUsers",


  confirmEmail: "/confirm-email",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:token",
  emailVerification: "/email-verification/:token",
  resetPasswordSuccess: "/reset-password-success",
  resetPasswordLinkSent: "/reset-password-link-sent",

  
  addAccount: "/add-account",
  accounts: "/demo-accounts",
  editAccount: "/edit-account/:id",
  accountView: "/account/:account_number",
  accountDetails: "account-details/:account_number",
  
  challenges: "/challenges",
  addChallenges: "/addChallenge",
  editChallenge: "/challenge/:id",
  
  fundedAccounts: "/funded-accounts",
  addFundedAccount: "/add-funded-account",
  editFundedAccount: "/edit-funded-account/:id",
  fundedAccountDetails: "/funded-account-details/:account_number",

  readRules: "/rules",
  createRules: "/rules/create",

  investors: "/investors/overview/:company",
  addInvestors: "/investors/add",
  editInvestors: "/investors/edit/:account_number",
  
  portfolio: "/portfolio",
  addClients: "/portfolio/clients/add",
  editClients: "/portfolio/clients/edit/:id",
  report: "/client/report/:id",


  //pmw
  home: "/home",
  bank_balance: "/bank_balance",
};