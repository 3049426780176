import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  read_clients: {
    ...initialState,
  },
  read_summary: {
    ...initialState,
  },
  read_report: {
    ...initialState,
  },
  create_client: {
    ...initialState,
  },
  update_client: {
    ...initialState,
  },
  delete_client: {
    ...initialState,
  },
  create_interest: {
    ...initialState,
  },
  update_interest: {
    ...initialState,
  },
  delete_interest: {
    ...initialState,
  },
  create_invest: {
    ...initialState,
  },
  update_invest: {
    ...initialState,
  },
  delete_invest: {
    ...initialState,
  },
  send_report: {
    ...initialState,
  },
  read_wp: {
    ...initialState,
  },

};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //create
    case types.protfolio.CREATE_CLIENTS:
      return {
        ...state,
        create_client: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.CREATE_CLIENTS_SUCCESS:
      return {
        ...state,
        create_client: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.CREATE_CLIENTS_ERROR:
      return {
        ...state,
        create_client: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.READ_CLIENTS:
      return {
        ...state,
        read_clients: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.READ_CLIENTS_SUCCESS:
      return {
        ...state,
        read_clients: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.READ_CLIENTS_ERROR:
      return {
        ...state,
        read_clients: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.READ_SUMMARY:
      return {
        ...state,
        read_summary: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.READ_SUMMARY_SUCCESS:
      return {
        ...state,
        read_summary: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.READ_SUMMARY_ERROR:
      return {
        ...state,
        read_summary: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.READ_REPORT:
      return {
        ...state,
        read_report: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.READ_REPORT_SUCCESS:
      return {
        ...state,
        read_report: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.READ_REPORT_ERROR:
      return {
        ...state,
        read_report: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.DELETE_CLIENTS:
      return {
        ...state,
        delete_client: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.DELETE_CLIENTS_SUCCESS:
      return {
        ...state,
        delete_client: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.DELETE_CLIENTS_ERROR:
      return {
        ...state,
        delete_client: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.EDIT_CLIENTS:
      return {
        ...state,
        update_client: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.EDIT_CLIENTS_SUCCESS:
      return {
        ...state,
        update_client: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.EDIT_CLIENTS_ERROR:
      return {
        ...state,
        update_client: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    
    case types.protfolio.CREATE_INTEREST:
      return {
        ...state,
        create_interest: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.CREATE_INTEREST_SUCCESS:
      return {
        ...state,
        create_interest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.CREATE_INTEREST_ERROR:
      return {
        ...state,
        create_interest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.DELETE_INTEREST:
      return {
        ...state,
        delete_interest: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.DELETE_INTEREST_SUCCESS:
      return {
        ...state,
        delete_interest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.DELETE_INTEREST_ERROR:
      return {
        ...state,
        delete_interest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.EDIT_INTEREST:
      return {
        ...state,
        update_interest: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.EDIT_INTEREST_SUCCESS:
      return {
        ...state,
        update_interest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.EDIT_INTEREST_ERROR:
      return {
        ...state,
        update_interest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    
    case types.protfolio.CREATE_INVEST:
      return {
        ...state,
        create_invest: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.CREATE_INVEST_SUCCESS:
      return {
        ...state,
        create_invest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.CREATE_INVEST_ERROR:
      return {
        ...state,
        create_invest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.DELETE_INVEST:
      return {
        ...state,
        delete_invest: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.DELETE_INVEST_SUCCESS:
      return {
        ...state,
        delete_invest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.DELETE_INVEST_ERROR:
      return {
        ...state,
        delete_invest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.EDIT_INVEST:
      return {
        ...state,
        update_invest: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.EDIT_INVEST_SUCCESS:
      return {
        ...state,
        update_invest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.EDIT_INVEST_ERROR:
      return {
        ...state,
        update_invest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    case types.protfolio.SEND_REPORT:
      return {
        ...state,
        send_report: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.SEND_REPORT_SUCCESS:
      return {
        ...state,
        send_report: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.SEND_REPORT_ERROR:
      return {
        ...state,
        send_report: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
      case types.protfolio.READ_WP:
      return {
        ...state,
        read_wp: {
          ...initialState,
          loading: true,
        }
      };
    case types.protfolio.READ_WP_SUCCESS:
      return {
        ...state,
        read_wp: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.protfolio.READ_WP_ERROR:
      return {
        ...state,
        read_wp: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    
    default:
      return state;
  }
};