import * as auth from "redux/selectors/auth";
import * as currentUser from "redux/selectors/currentUser";
import * as theme from "redux/selectors/theme";
import * as users from "redux/selectors/users";
import * as cUsers from "redux/selectors/cUsers";
import * as accounts from "redux/selectors/accounts";
import * as statistics from "redux/selectors/statistics";
import * as challenges from "redux/selectors/challenges";
import * as fundedAccounts from "redux/selectors/fundedAccounts";
import * as dashboard from "redux/selectors/dashboard";
import * as rules from "redux/selectors/rules";
import * as iaccounts from "redux/selectors/investors";
import * as portfolio from "redux/selectors/portfolio";

export default {
  auth,
  currentUser,
  theme, 
  users,
  accounts,
  statistics,
  challenges,
  fundedAccounts,
  dashboard,
  rules,
  iaccounts,
  portfolio,
  cUsers,
};
