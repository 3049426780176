const hostname = window.location.hostname;

const isLocalHost = () => {
  return !!(
    hostname.indexOf('localhost') > -1 ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
};

let config;
if (isLocalHost()) {
  config = {
    apiBase: 'http://localhost:8080',
    cookieDomain: window.location.hostname,
    env: 'local',
  };
}

config = {
  apiBase: 'https://api.pmw-trading.com', 
  cookieDomain: window.location.hostname,
  env: 'local',
};

export default {
  ...config,
  sentryDSN:
    '',
};
