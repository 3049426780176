/*eslint-disable*/
import React, {useState} from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import './sidebar.scss';
import { useLocation } from 'react-router-dom'

import { useSelector } from "react-redux";
import { Menu, Layout } from 'antd';
import {
  DashboardOutlined,
  PlusCircleOutlined,
  AppstoreOutlined,
  SettingOutlined,
  TeamOutlined,
  PlusCircleFilled,
  PartitionOutlined,
  UserSwitchOutlined,
  FundFilled,
  PieChartFilled,
  FundOutlined,
  UserOutlined,
  BankOutlined
} from '@ant-design/icons';

import logo from "./logo.png";
import icon from "./favicon.ico";

const { Sider } = Layout;
const { SubMenu } = Menu;
export default (props) => {
  const {currentUser} = props;
  const [mode, setMode] = useState('inline');
  const themeState = useSelector(state=>state.theme);
  const { innerWidth: width } = window;
  const [collapsed, setCollapsed] = useState(width <= 992);
  
  const getCurrentRoute = ()=>{
    const location = useLocation();
    return location?.pathname.replace(/\//g, "") ? location?.pathname.replace(/\//g, "") : '';
  }

  const changeMode = value => {
    setMode(value ? 'vertical' : 'inline');
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };



  return (
    <div className="site-navigation">
      <Sider 
        collapsible 
        collapsed={collapsed} 
        onCollapse={onCollapse}
        width={256}
        height="100%"
        theme={themeState?.theme ? themeState?.theme : 'light'}
        className={`sidebar-menu-${themeState.theme}`}
      >
        <div className="logo">
          <div className="sidebar-title">
            <Link to={paths.home}>
              {collapsed? <img src={icon} className="sidebar-icon"/> : <img src={logo} className="sidebar-logo"/>}
            </Link>
          </div>
        </div>
        <Menu
          defaultSelectedKeys={[getCurrentRoute()]}
          mode={mode}
          theme={themeState?.theme ? themeState?.theme : 'light'}
          multiple={false}
          inlineIndent={20}
          style={{position:'fixed', maxWidth:"256px"}}
        >
          {currentUser.role === "ADMIN" ?
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to={paths.dashboard}>
              Dashboard
            </Link>
          </Menu.Item>
          :null}
          
          {currentUser.role === "ADMIN" ?
            <Menu.Item key="portfolio" icon={<PieChartFilled />} >
              <Link to={paths.portfolio}>
                Investments
              </Link>
            </Menu.Item>
          :null}
          
          {currentUser.role === "ADMIN" ?
          <Menu.Item key="demo-accounts" icon={<PlusCircleOutlined />} >
            <Link to={paths.accounts}>
              Demo Accounts
            </Link>
          </Menu.Item>
          :null}

          {currentUser.role === "ADMIN" ?
          <Menu.Item key="funded-accounts" icon={<PlusCircleFilled />} >
            <Link to={paths.fundedAccounts}>
              Funded Accounts
            </Link>
          </Menu.Item>
          :null}

          {currentUser.role === "ADMIN" ?
          <SubMenu key="live_account" icon={<FundFilled />} title="Live Accounts">
            <Menu.Item key="investors/erba" icon={<FundOutlined />}>
              <Link to={paths.investors.replace(":company", "erba")}>
                Erba Trading Group
              </Link>
            </Menu.Item>
            <Menu.Item key="investors/pmw" icon={<FundOutlined />}>
              <Link to={paths.investors.replace(":company", "pmw")}>
                PMW Holding
              </Link>
            </Menu.Item>
          </SubMenu>
          :null}
          
          {currentUser.role === "ADMIN" ?
          <Menu.Item key="challenges" icon={<AppstoreOutlined />}>
            <Link to={paths.challenges}>
              Challenges
            </Link>
          </Menu.Item>
          :null}
          {currentUser.role === "ADMIN" ?
          <SubMenu key="users_menu" icon={<TeamOutlined />} title="Users">
            <Menu.Item key="users" icon={<UserOutlined />}>
              <Link to={paths.users}>
                Users
              </Link>
            </Menu.Item>
            <Menu.Item key="cUsers" icon={<UserOutlined />}>
              <Link to={paths.cUsers}>
                Investors
              </Link>
            </Menu.Item>
          </SubMenu>
          :null}

          {currentUser.role === "ADMIN" ?
          <SubMenu key="readRules" icon={<SettingOutlined />} title="Settings">
            <Menu.Item key="rules" icon={<PartitionOutlined />}>
              <Link to={paths.readRules}>
                Rules
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="privileges" icon={<UserSwitchOutlined />}>
              <Link to={paths.settings}>
                Roles & Privileges
              </Link>
            </Menu.Item> */}
            <Menu.Item key="bank_balance" icon={<BankOutlined />}>
              <Link to={paths.bank_balance}>
                PMW Bank Balance
              </Link>
            </Menu.Item>
          </SubMenu>
          :null}

          {/*PMW View */}

          {currentUser.role === "PMW" ?
          <Menu.Item key="home" icon={<DashboardOutlined />}>
            <Link to={paths.home}>
              Dashboard
            </Link>
          </Menu.Item>
          :null}

          {currentUser.role === "PMW" ?
            <Menu.Item key="portfolio" icon={<PieChartFilled />} >
              <Link to={paths.portfolio}>
                Investments
              </Link>
            </Menu.Item>
          :null}
          
          {/* {currentUser.role === "PMW" ?
            <Menu.Item key="investors/pmw" icon={<FundOutlined />}>
              <Link to={paths.investors.replace(":company", "pmw")}>
                Live Accounts
              </Link>
            </Menu.Item>
          :null} */}
        </Menu>
        
      </Sider>
    </div>
  );
};
