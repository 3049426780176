
export const createIAccount = (state) => {
  return state.iaccounts.create_i;
};

export const updateIAccount = (state) => {
  return state.iaccounts.update_i;
};

export const readIAccount = (state) => {
  return state.iaccounts.read_i;
};

export const deleteIAccount = (state) => {
  return state.iaccounts.delete_i;
};

export const readAllIAccount = (state) => {
  return state.iaccounts.readAll_i;
};

export const readISummary = (state) => {
  return state.iaccounts.isummary;
};

export const readIDailySummary = (state) => {
  return state.iaccounts.idaily_summary;
};

export const readCompanies = (state) => {
  return state.iaccounts.icompanies;
};

export const readTrades = (state) => {
  return state.iaccounts.itrades;
};


export const readDeposit = (state) => {
  return state.iaccounts.readDeposit;
};

export const updateDeposit = (state) => {
  return state.iaccounts.updateDeposit;
};

export const createDeposit = (state) => {
  return state.iaccounts.createDeposit;
};

export const readOverview = (state) => {
  return state.iaccounts.readOverview;
};