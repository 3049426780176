export default {

  CREATE_IACCOUNT: "/iaccounts/create_i/request",
  CREATE_IACCOUNT_SUCCESS: "/iaccounts/create_i/success",
  CREATE_IACCOUNT_ERROR: "/iaccounts/create_i/error",

  UPDATE_IACCOUNT: "/iaccounts/update_i/request",
  UPDATE_IACCOUNT_SUCCESS: "/iaccounts/update_i/success",
  UPDATE_IACCOUNT_ERROR: "/iaccounts/update_i/error",

  READ_IACCOUNT: "/iaccount/read_i/request",
  READ_IACCOUNT_SUCCESS: "/iaccount/read_i/success",
  READ_IACCOUNT_ERROR: "/iaccount/read_i/error",

  DELETE_IACCOUNT: "/iaccounts/delete_i/request",
  DELETE_IACCOUNT_SUCCESS: "/iaccounts/delete_i/success",
  DELETE_IACCOUNT_ERROR: "/iaccounts/delete_i/error",

  READ_IACCOUNTS: "/iaccounts/read_i/request",
  READ_IACCOUNTS_SUCCESS: "/iaccounts/read_i/success",
  READ_IACCOUNTS_ERROR: "/iaccounts/read_i/error",

  READ_ISUMMARY: "/iaccounts/summary/request",
  READ_ISUMMARY_SUCCESS: "/iaccounts/summary/success",
  READ_ISUMMARY_ERROR: "/iaccounts/summary/error",

  READ_IDAILYSUMMARY: "/iaccounts/daily_summary/request",
  READ_IDAILYSUMMARY_SUCCESS: "/iaccounts/daily_summary/success",
  READ_IDAILYSUMMARY_ERROR: "/iaccounts/daily_summary/error",

  READ_COMPANIES: "/iaccounts/companies/request",
  READ_COMPANIES_SUCCESS: "/iaccounts/companies/success",
  READ_COMPANIES_ERROR: "/iaccounts/companies/error",

  READ_TRADES: "/iaccounts/trades/request",
  READ_TRADES_SUCCESS: "/iaccounts/trades/success",
  READ_TRADES_ERROR: "/iaccounts/trades/error",


  READ_DEPOSIT: "/deposit/read/request",
  READ_DEPOSIT_SUCCESS: "/deposit/read/success",
  READ_DEPOSIT_ERROR: "/deposit/read/error",

  UPDATE_DEPOSIT: "/deposit/update/request",
  UPDATE_DEPOSIT_SUCCESS: "/deposit/update/success",
  UPDATE_DEPOSIT_ERROR: "/deposit/update/error",

  CREATE_DEPOSIT: "/deposit/create/request",
  CREATE_DEPOSIT_SUCCESS: "/deposit/create/success",
  CREATE_DEPOSIT_ERROR: "/deposit/create/error",

  READ_OVERVIEW: "/dashboard/overview/request",
  READ_OVERVIEW_SUCCESS: "/dashboard/overview/success",
  READ_OVERVIEW_ERROR: "/dashboard/overview/error",
};